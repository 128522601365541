.loadingindicator-container {
  position: relative;
  display: flex;
  width: var(--loadingindicator-size);
  height: var(--loadingindicator-size);
}

.indicator-container,
.loadingindicator-smootherContainer {
  position: absolute;
  overflow: hidden;
  width: var(--loadingindicator-size);
  height: calc(var(--loadingindicator-size) * 0.5);
  transform-origin: calc(var(--loadingindicator-size) * 0.5) calc(var(--loadingindicator-size) * 0.5);
}

.indicator-container > div,
.loadingindicator-smoother {
  width: 100%;
  height: 100%;
  border-radius: calc(var(--loadingindicator-size) * 0.5) calc(var(--loadingindicator-size) * 0.5) 0 0;
  transform-origin: 50% 100%;
  transform: rotateZ(181deg);
  animation-name: agge;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.loadingindicator-smootherContainer {
  animation-name: snurra;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.loadingindicator-smoother {
  animation-name: fixer;
  animation-duration: 20s;
}

@keyframes fixer {
  0% {
    transform: rotateZ(calc(180deg - var(--loadingindicator-steps)));
  }

  60%,
  100% {
    transform: rotateZ(calc(180deg - var(--loadingindicator-steps) + 2deg));
  }
}

@keyframes snurra {
  from {
    transform: rotateZ(0deg);
  }
  to {
    transform: rotateZ(360deg);
  }
}

@keyframes agge {
  0% {
    transform: rotateZ(calc(180deg - var(--loadingindicator-steps)));
  }

  60%,
  100% {
    transform: rotateZ(181deg);
  }
}
