.snackbar-content {
  min-width: 350px;
  margin-left: -125px;
  background-color: rgba(20, 20, 20, 0.7);
  color: #fff;
  padding: 15px;
  white-space: pre-wrap;
  position: fixed;
  z-index: 60;
  right: 5%;
  top: var(--snackbar-top-pos);
  animation: fadein 0.5s, fadeout 0.5s var(--persistTimeSnackbar);
  display: flex;
  box-shadow: 0px 0px 10px 2px #888888;
}

.snackbar-content div:first-of-type {
  margin: auto 5px;
  display: flex;
}

.snackbar-content p {
  margin: auto auto auto 20px;
}

.snackbar-content div:last-of-type {
  margin: auto 0 auto auto;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  padding: 5px;
}

.snackbar-content div:last-of-type:hover {
  opacity: 0.5;
}

@keyframes fadein {
  from {
    top: 0;
    opacity: 0;
  }
  to {
    top: var(--snackbar-top-pos);
    opacity: 1;
  }
}

@keyframes fadeout {
  from {
    top: var(--snackbar-top-pos);
    opacity: 1;
  }
  to {
    top: 0;
    opacity: 0;
  }
}
