.expandeble-complete-header {
  display: flex;
  cursor: pointer;
  overflow: hidden;
  min-height: 32px;
  position: relative;
}

.expander-complete-color-active {
  background-color: lightblue;
}

.expander-complete-color-active:hover {
  background-color: lightcyan;
}

.expander-complete-color-inactive {
  background-color: transparent;
}

.expander-complete-color-inactive:hover {
  background-color: #ebebeb;
}

.expander-complete-color-inactive-drag {
  background-color: white;
}

.expander-complete-color-active-drag {
  background-color: lightcyan;
}

.expandeble-icon {
  transition: all 0.2s ease-out;
  margin: auto 0;
  display: flex;
}

.expandeble-complete-section {
  transition: max-height 0.2s ease-out;
  overflow: hidden;
  width: 100%;
}

.tree-docsBelow {
  margin: auto 0;
  margin-left: 2px;
  margin-top: -4px;
  margin-bottom: 0;
  overflow: auto;
}

.tree-upperArea {
  display: flex;
  border-bottom: solid lightgray 1px;
  padding-bottom: 5px;
  margin-bottom: 5px;
  min-height: 40px;
  margin-top: 10px;
  flex: 0 0 auto;
  flex-wrap: wrap-reverse;
}

.tree-upperAreaDivToPositionHeadings {
  display: flex;
  flex: 1 1 488px;
}

.tree-masterCheckDocCount {
  margin-top: auto;
  margin-bottom: 4px;
  margin-right: 4px;
  white-space: pre-wrap;
  margin-left: 4px;
  min-width: 60px;
}

.tree-controls {
  display: flex;
  margin-right: 10px;
  margin-left: 10px;
  margin-top: auto;
  margin-top: 5px;
  gap: 10px;
}

.tree-headingsType {
  margin: auto 0;
  font-weight: bold;
  flex: 0 1 330px;
  text-align: left;
}

.tree-headingsRevisionType {
  margin: auto 0;
  font-weight: bold;
  flex: 0 1 115px;
  text-align: right;
  padding-right: 25px;
}

.tree-userAccessFromDelNameHeading {
  margin: auto 5px 0 auto;
  font-weight: bold;
  margin-right: 20px;
}

.tree-curtainmain {
  flex: 1 1 auto;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
}

.tree-main {
  flex: 1 1 auto;
  display: flex;
  overflow-y: auto;
  flex-direction: column;
  margin-top: 5px;
}

.tree-findMain {
  display: flex;
  flex-direction: column;
  padding: 0px 50px;
  min-width: 730px;
  flex: 1 1 auto;
  width: 100%;
  overflow-y: hidden;
}

.tree-documentArea {
  flex: 1 1 0px;
  overflow-y: auto;
}

.tree-cataloguemain {
  flex: 1 1 auto;
  max-width: 1400px;
  min-width: 760px;
  display: flex;
  flex-direction: column;
  overflow-x: auto;
}

.tree-minimalmain {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: auto;
}

.tree-minimalmain > article {
  overflow-y: auto;
}

.tree-usearAccessFromDelMain {
  overflow: auto;
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 250px);
}

.tree-fm-noScrollPart {
  display: flex;
  flex-basis: 100%;
  margin-right: 20px;
}

.tree-fm-headingsType {
  margin: auto 0;
  font-weight: bold;
  flex:1 1 30%;
}

.tree-fm-headingsRevision {
  margin: auto 0;
  font-weight: bold;
  flex: 1 1 10%;
  text-align: right;
  min-width: 10ch;
}

.tree-fm-leftPart {
  display: flex;
  flex: 1 1 50%;
  gap: 10px;
}

.tree-fm-menuHolder {
  flex: 0 0 32px;
  margin-left: 5px;
}

@media screen and (max-width: 700px) {
  .tree-findMain {
    padding: 0px 5px;
  }
}
