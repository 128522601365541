.login-link a {
  color: #3366ff;
  text-decoration: none;
}

.login-link a:hover {
  color: #2a35ff;
}

.login-link a:active {
  color: #2c20d4;
}

@media screen and (max-width: 720px) {
  .login-productMainName {
    font-size: 0.8em;
  }
}
