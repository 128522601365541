.curtainDocument-container,
.curtainDocument-container-selected {
  padding: 0;
  cursor: pointer;
  border-right: transparent 4px solid;
  display: flex;
}

.curtainDocument-container-selected {
  cursor: unset;
  border-right: #3366ff 4px solid;
}

.curtainDocument-container:hover {
  background-color: #ebebeb;
}

.document-container {
  display: flex;
  width: 100%;
  height: 32px;
}

.document-noScrollPart {
  display: flex;
  flex-basis: 100%;
}

.document-userAccessName {
  margin: auto;
  margin-right: 0;
  padding-left: 10px;
  height: 32px;
  white-space: nowrap;
  line-height: 32px;
  overflow-x: visible;
}

.document-container:hover {
  background-color: #ebebeb;
}

.document-noScrollPart:hover {
  background-color: #ebebeb;
}

.document-searchsnippet {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  font-style: italic;
}

.new-keyword {
  margin: auto 0;
  margin-left: 10px;
  margin-right: 5px;
  margin-left: auto;
}

.new-keyword > div {
  display: flex;
}

.document-revisionPart {
  margin-left: 5px;
  min-width: 75px;
  text-align: right;
  white-space: nowrap;
  overflow: hidden;
  margin: auto 0;
  margin-right: 10px;
}

.document-containerNoCheck {
  margin: auto 0;
  width: 100%;
  display: flex;
  overflow-x: hidden;
}

.document-text {
  flex: 1;
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: flex;
  overflow-x: hidden;
  margin: auto 0;
}

.document-catalogue-leftPart {
  display: flex;
  overflow-x: hidden;
  flex: 1 1 488px;
}

.document-type {
  margin: auto 0;
  overflow: hidden;
  line-height: 32px;
  height: 32px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.doctype-revision {
  margin: auto 0;
  flex: 0 1 115px;
  padding-right: 25px;
  white-space: nowrap;
  text-align: right;
  overflow: hidden;
}

.document-revisionDropdownPart {
  min-width: 100px;
  margin: auto 0;
}

.updateDelDocument-topCat {
  font-weight: bold;
}

.document-noScrollPart {
  display: flex;
  flex-basis: 100%;
  margin-right: 20px;
}

.document-leftPart {
  display: flex;
  flex: 1 1 50%;
  overflow: hidden;
}

.document-fm-type {
  flex: 1 1 30%;
  margin: auto 0;
  overflow: hidden;
  line-height: 32px;
  height: 32px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.document-fm-revision {
  flex: 1 1 10%;
  margin: auto 0;
  white-space: nowrap;
  overflow: hidden;
  text-align: right;
  min-width: 10ch;
  text-overflow: ellipsis;
  white-space: nowrap;
}
