/* @font-face { */
/*   font-family: "ABBfont"; */
/*   src: url("./ABBvoice_W_Lt.woff2") format("woff2"); */
/* } */

* {
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */
  font-family: "Segoe UI", sans-serif;
}

body {
  margin: 0;
  padding: 0;
}

.App {
  text-align: center;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */
  background-color: #ebebeb;
}

p {
  margin: 0;
}

.tooltip {
  padding: 10px;
  border-radius: 10px;
  background-color: white;
  white-space: pre-wrap;
}

.searchCommonUx > span {
  cursor: pointer;
}

.swacTextArea {
  padding: 5px 10px;
  height: 100%;
  width: 100%;
  margin: 0;
}

.swacTextArea:hover {
  background-color: #ebebeb;
}
