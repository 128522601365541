.expander-nameArea {
  display: flex;
  padding: 0;
  flex: 1;
  width: 100%;
  white-space: nowrap;
  display: flex;
  overflow: hidden;
  margin: auto 0;
}

.expandable-catalogueNewIcon {
  display: flex;
}

.expandable-blockRight {
  width: 150px;
}

.delexpandable-delstatus {
  margin-left: 22px;
}

.expander-nameArea > p:first-of-type {
  margin: auto 0;
  padding: 0px;
}
